<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-row :gutter='24'>
      <a-col :sm='12' :md='6' :xl='24' :style="{ marginBottom: '24px' }">
        <a-card :loading='loading' >
          <div class="card_content" >
            <a-row>
              <a-col :span="3"><span class="card_title">用户数</span></a-col>
              <a-col :span="3"><span class="card_title">份额总数</span></a-col>
              <a-col :span="3"><span class="card_title">推广份额数</span></a-col>
<!--              <a-col :span="3"><span class="card_title">未推广份额数</span></a-col>-->
              <a-col :span="3"><span class="card_title">超出份额</span></a-col>
              <a-col :span="3"><span class="card_title">奖励推广业绩</span></a-col>
<!--              <a-col :span="3"><span class="card_title">奖励未推广业绩</span></a-col>-->
              <a-col :span="3"><span class="card_title">奖励积分</span></a-col>
              <a-col :span="3"><span class="card_title">推广份值</span></a-col>
              <a-col :span="2"><span class="card_title">未推广份值</span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-col :span="3"><span class="color-red">{{ dashboard.memberCount}}</span></a-col>
              <a-col :span="3"><span class="color-red">{{ dashboard.shareCountBalance}}</span></a-col>
              <a-col :span="3"><span class="color-red">{{ dashboard.spreaderShare}}</span></a-col>
<!--              <a-col :span="3"><span class="color-red">{{ dashboard.notSpreaderShare}}</span></a-col>-->
              <a-col :span="3"><span class="color-red">{{ dashboard.overShare }}</span></a-col>
              <a-col :span="3"><span class="color-red">{{ dashboard.performance }}</span></a-col>
<!--              <a-col :span="3"><span class="color-red">{{ dashboard.notFastPerformance }}</span></a-col>-->
              <a-col :span="3"><span class="color-red">{{ dashboard.totalIntegral }}</span></a-col>
              <a-col :span="3"><span class="color-red">{{ dashboard.spreaderValue }}</span></a-col>
              <a-col :span="2"><span class="color-red">{{ dashboard.notSpreaderValue }}</span></a-col>
            </a-row>
          </div>
        </a-card>
      </a-col>
    </a-row>

    <a-card :bordered='false'>
      <a-form-model ref='form' :model='form' layout='inline'>
        <a-form-model-item prop='masterName'>
          <a-input v-model='form.masterName' style="width: 200px" placeholder="姓名" />
        </a-form-model-item>

        <a-form-model-item prop='masterMobile'>
          <a-input v-model='form.masterMobile' style="width: 200px" placeholder="电话" />
        </a-form-model-item>

        <a-form-model-item prop='valueName'>
          <a-select v-model="form.valueName" placeholder="份值" style="width: 200px">
            <a-select-option :value="undefined">
              全部
            </a-select-option>
            <a-select-option value="未推广">
              未推广
            </a-select-option>
            <a-select-option value="推广0.5">
              推广0.5
            </a-select-option>
            <a-select-option value="推广1">
              推广1
            </a-select-option>
            <a-select-option value="推广1.5">
              推广1.5
            </a-select-option>
            <a-select-option value="推广2">
              推广2
            </a-select-option>
            <a-select-option value="推广2.5">
              推广2.5
            </a-select-option>
            <a-select-option value="推广3">
              推广3
            </a-select-option>
            <a-select-option value="推广3.5">
              推广3.5
            </a-select-option>
            <a-select-option value="推广4">
              推广4
            </a-select-option>
            <a-select-option value="推广4.5">
              推广4.5
            </a-select-option>
            <a-select-option value="推广5">
              推广5
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <!--        <a-form-model-item prop='date'>-->
        <!--          <a-date-picker-->
        <!--            v-model="dateArr"-->
        <!--            :format="[formatType]"-->
        <!--            :show-time="{-->
        <!--            hideDisabledOptions: true-->
        <!--          }"-->
        <!--            :disabled-date="disabledDate"-->
        <!--            @change="dateChange"-->
        <!--          />-->
        <!--        </a-form-model-item>-->

        <a-form-model-item>
          <a-button type='primary' @click='handleSubmit'>
            查询
          </a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click='handleReset'>
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>

      <s-table ref='table' rowKey='id' :columns='columns' :scroll='{ x: "100%"}' :data='loadData'>
        <span slot='recordType' slot-scope='text, record'>
          <template>
            {{text=='19'?'普通':'加速'}}
          </template>
        </span>
<!--        <span slot='fillStatus' slot-scope='text, record'>-->
<!--          <template>-->
<!--            {{getFillStatusStr(text)}}-->
<!--          </template>-->
<!--        </span>-->
      </s-table>
    </a-card>


  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import {memberHeardData, queryOperators} from '@/api/operator'
import { isVideoUrl } from '@/utils/util'
import moment from "moment";
import {queryJiangLiCashDetailList, queryJiangLiHeard} from "@/api/cash";


const columns = [
  {
    width: 80,
    title: '日期',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 80,
    title: '姓名',
    dataIndex: 'masterName',
    scopedSlots: { customRender: 'masterName' }
  },
  // {
  //   width: 100,
  //   title: '奖励目标',
  //   dataIndex: 'lastGoalName',
  //   scopedSlots: { customRender: 'lastGoalName' }
  // },
  {
    width: 80,
    title: '电话',
    dataIndex: 'masterMobile',
    scopedSlots: { customRender: 'masterMobile' }
  },

  // {
  //   width: 80,
  //   title: '待有效份额数',
  //   dataIndex: 'thisShareCount',
  //   scopedSlots: { customRender: 'thisShareCount' }
  // },// 已完成目标的总份额数 总份额数->进行中额数(已完成目标的总份额数：thisShareCount)20240422版本
  {
    width: 80,
    title: '剩余份额数',
    dataIndex: 'shareValue',
    scopedSlots: { customRender: 'shareValue' }
  },// 已完成目标的总份额数 新增份额数->原始份额数(已完成目标的总份额数：addShareCount)20240422版本---finishedGoalTotalShareCount
  {
    width: 80,
    title: '奖励后剩余份额数',
    dataIndex: 'afterSubstractShareCount',
    scopedSlots: { customRender: 'afterSubstractShareCount' }
  },// 3、增加奖励后剩余份额数 = 扣掉奖励积分后剩余的份额数 20240610
  {
    width: 80,
    title: '累计值',
    dataIndex: 'cumulativeValue',
    scopedSlots: { customRender: 'cumulativeValue' }
  },
  {
    width: 80,
    title: '实时累计值',
    dataIndex: 'cumulativeValueBalance',
    scopedSlots: { customRender: 'cumulativeValueBalance' },
    // sorter: true,
  },
  // {
  //   width: 80,
  //   title: '份值',
  //   dataIndex: 'fillStatus;',
  //   scopedSlots: { customRender: 'fillStatus' }
  // },
  {
    width: 80,
    title: '份值',
    dataIndex: 'valueName',
    scopedSlots: { customRender: 'valueName' }
  },
  // {
  //   width: 100,
  //   title: '类型',
  //   dataIndex: 'recordType',
  //   scopedSlots: { customRender: 'recordType' }
  // },
  {
    width: 80,
    title: '积分奖励',
    dataIndex: 'totalIntegral',
    scopedSlots: { customRender: 'totalIntegral' }
  },
  {
    width: 80,
    title: '分享积分85%',
    dataIndex: 'cash',
    scopedSlots: { customRender: 'cash' }
  },
  {
    width: 80,
    title: '兑换积分15%',
    dataIndex: 'exchangeIntegral',
    scopedSlots: { customRender: 'exchangeIntegral' }
  },
]

const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('date').format(formatType);


export default {
  name: 'ProposalEdit',

  data() {
    return {
      dashboard: {}, //表头用户数统计
      form: {
      },
      formatType,
      // dateArr: baseStart,
      id:"",
      loading: true,
      profile: {},
      goodImagesList: [],
      badImagesList: [],
      usedImagesList: [],
      content: "",
      previewVisible: false,
      previewImage: "",
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        let params = Object.assign(parameter, this.queryParam,this.form,{areaId:this.id});
        delete params.date;
        let sort = params.sortField;
        return queryJiangLiCashDetailList(params)
            .then(datum => {
              return datum
            })
      }
    }
  },
  components: { STable, Ellipsis },
  created() {
    this.handleInitial()
  },
  activated() {
    this.handleInitial();
    this.getLocalData();
  },
  methods: {
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    // dateChange() {
    //
    //   this.form.date =  this.dateArr ? moment(this.dateArr).format(this.formatType) : "";
    // },
    handleSubmit() {
      this.$refs.table.refresh(true)
      this.loading=true;
      this.getHeaderData();
    },
    handleReset() {
      this.dateArr = baseStart;
      // this.form.start = moment(baseStart).startOf('day').format(this.formatType + " HH:mm");

      // this.form.date = moment(baseStart).endOf('day').format(this.formatType);
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleInitial() {
      // this.dateArr = this.$route.query.date;
      // this.form.date = moment(this.dateArr).endOf('day').format(this.formatType);
      let bs = baseStart;

      let tempCreate =  this.$route.query.createTime;
      let tempDate =  moment(baseStart).startOf('day').format(formatType);
      let date = this.$route.query.date;

      if(!tempCreate){
        tempCreate = tempDate +" 00:00:00";
      }
      if(!date){
        date = tempDate;
      }

      this.form.createTime = tempCreate;
      this.form.date = tempCreate;
      this.form.tempDate = tempDate;

      this.id= this.$route.query.id;
      this.getHeaderData();
    },
    getLocalData(){
      this.$refs.table.refresh(true)
    },
    getHeaderData(){
      let _from = this.form;

      let params = Object.assign({areaId:this.id,date:this.form.date});
      queryJiangLiHeard(params).then(result => {
        // debugger
        this.dashboard = result;
      })
      this.loading=false;
    },

  }
}
</script>
<style lang="less" scoped>
.image_list {
  width: 150px;
  height: 150px;
  margin: 0 20px 20px 0;
  object-fit: cover;
  position: relative;
  .image_detail {
    height: 100%;
    width: 100%;
  }
  .play_image {
    position: absolute;
    width: 50px;
    height: 50px;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
  }
}
</style>
